.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.serv2Carrier{
  width: 100%;
  background-image: url("./Image/5/3.jpg");
  border-radius: 8px;  
  background-repeat: no-repeat;
  background-size: cover !important;
  height: 65vh !important;
}
.serv2Carrier1{
  width: 100%;
  background-image: url("./Image/3/4.jpg");
  border-radius: 8px;  
  background-repeat: no-repeat;
  background-size: cover !important;
  height: 65vh !important;
}
.serv2Carrier2{
  width: 100%;
  background-image: url("./Image/2/6.jpg");
  border-radius: 8px;  
  background-repeat: no-repeat;
  background-size: cover !important;
  height: 65vh !important;
}
.serv2Carrier3{
  width: 100%;
  background-image: url("./Image/3/6.jpg");
  border-radius: 8px;  
  background-repeat: no-repeat;
  background-size: cover !important;
  height: 65vh !important;
}
.serv2Carrier4{
  width: 100%;
  background-image: url("./Image/4/3.jpg");
  border-radius: 8px;  
  background-repeat: no-repeat;
  background-size: cover !important;
  height: 65vh !important;
}
.servDiv{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.48);
  height: 100%;
  border-radius: 8px;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 10%;
  color: #ffffff;
}
.servDiv h2{
  font-weight: bold !important;
}
.servDiv p{
  margin-top: -0.7rem;
}
.serv{
  padding-left: 1rem;
  padding-top: 20rem;
  color: #ffffff;
}
.serv h2{
  font-weight: bold !important;
}
.serv p{
  margin-top: -0.7rem;
}
.keyStepText{
  font-weight: bold !important;
  font-size: 32px;
}
.circleDivCarrier{
  display: flex;
  gap: .5rem;
}
.drawCircle{
  width: 1.2rem;
  height: 1.2rem;
  background-color: black;
  border-radius: 50rem;
}
.drawCircle2{
  width: 1.2rem;
  height: 1.2rem;
  background-color: black;
  border-radius: 50rem;
}
.drawCircle1{
  width: 1rem;
  height: 1rem;
  background-color: black;
  border-radius: 50rem;
}
.fontWeightText{
  font-weight: bold !important;
  font-size: 26px;
}
.solarImg img{
  width: 100%;
}
.item{
  width: 100%;
}
.ser{
  padding: 1.5rem 0;
}
.paraTextCarrier{
  padding-right: 1.3rem;
}
.fontWeightTex{
  font-weight: bold !important;
}
.subHeading{
  font-weight: bold;
}
.owl-nav {
  position: absolute !important;
  top: 50% !important;
  width: 96%;
  right: 2%;
  left: 2%;
  z-index: 100;
}
.owl-nav button.owl-prev {
  float: left;
}
.owl-nav button.owl-next {
  float: right !important;
}
.owl-carousel .owl-nav button.owl-next, .owl-carousel .owl-nav button.owl-prev, .owl-carousel button.owl-dot {
  color: #fff !important;
  background-color: rgb(0, 0, 0) !important;
  border-radius: 50%;
  width: 52px;
  height: 52px;
  font-size: 24px;
}
.owl-carousel .owl-dots.disabled, .owl-carousel .owl-nav.disabled {
  display: none;
}
.owl-dots .owl-dot {
  display: none;
}
.owl-dots .owl-dot span {
  display: none;
}
.owl-theme .owl-dots .owl-dot span {
  display: none;
}
.owl-theme .owl-dots .owl-dot {
  display: none;
  zoom: 1;
}
.owl-carousel .owl-nav button.owl-next, .owl-carousel .owl-nav button.owl-prev, .owl-carousel button.owl-dot {
  width: 32px;
  height:32px;
  }

/* .owl-carousel .owl-nav button.owl-next, .owl-carousel .owl-nav button.owl-prev, .owl-carousel button.owl-dot {
  color: #fff !important;
  background-color: rgb(0, 0, 0) !important;
  border-radius: 50%; */
  /* opacity: .5;
  width: 52px;
  height: 52px;
  font-size: 24px;
} */


@media screen and (max-width: 540px)  {
  .keyStepText{
    font-size: 25px;
  }
  fontWeightText {
    font-size: 20px;
  }
  .drawCircle2 {
    width: 1.4rem;
  }
  .drawCircle1 {
    width: 1.7rem;
  }
  .drawCircle3{
    width: 2.35rem;
  }
  .drawCircle4{
    width: 2rem;
  }

  .owl-nav {
    position: absolute !important;
    top: 50% !important;
    width: 96%;
    right: 2%;
    left: 2%;
    z-index: 100;
  }
  .owl-nav button.owl-prev {
    float: left;
  }
  .owl-nav button.owl-next {
    float: right !important;
  }
  .owl-carousel .owl-nav button.owl-next, .owl-carousel .owl-nav button.owl-prev, .owl-carousel button.owl-dot {
    color: #fff !important;
    background-color: rgb(0, 0, 0) !important;
    border-radius: 50%;
    width: 52px;
    height: 52px;
    font-size: 24px;
  }
  .owl-carousel .owl-dots.disabled, .owl-carousel .owl-nav.disabled {
    display: none;
  }
  .owl-dots .owl-dot {
    display: none;
  }
  .owl-dots .owl-dot span {
    display: none;
  }
  .item{
    width: 100%;
  }
  .solarImg img{
    width: 100%;
  }
  .owl-carousel .owl-item img {
    display: block;
    width: 100%;
}
}