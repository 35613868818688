.sectionOne{
    width: 100vw;
}
.sliderParent{
    display: flex;
    width: 100vw;
}
.boxOne{
    height:80vh;
    width: 50vw;
    background-color: #F4FCFE;
    /* background-color: #3F8FAB; */
    z-index: 100!important;
    padding-top:10%;
}
.boxTwo{
    height:80vh;
    width: 50vw;
}
.boxAbtTwo{
    height:40vh;
    width: 60vw;
}

/* aboutus css */

.boxAbtOne{
    padding:0 10%;
    width: 100%;
    height:auto;
    padding-top:5%;
    /* background-color: #044389;
    color: #fff; */
}

.abtInfoTop{
    margin-bottom: 24px;
    font-size: 70px;
    line-height: 74px;
    font-weight: 500;
}
.slide{
    position: relative;
    width: 40vw;
    left: 15%;
}
.slideTitle{
    margin-bottom: 24px;
    font-size: 70px;
    line-height: 74px;
    font-weight: 500;
}